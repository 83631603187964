import postCategoriesJson from '../../../components/blocks/Post Categories/.component.json';
import postCountersJson from '../../../components/blocks/Post Counters/.component.json';
import postHeaderJson from '../../../components/blocks/Post Header/.component.json';
import postMetadataJson from '../../../components/blocks/Post Metadata/.component.json';
import postRatingsJson from '../../../components/blocks/Post Ratings/.component.json';
import postShareButtonsJson from '../../../components/blocks/Post Share Buttons/.component.json';
import postTagsJson from '../../../components/blocks/Post Tags/.component.json';
import postTitleJson from '../../../components/blocks/Post Title/.component.json';
import postWriterJson from '../../../components/blocks/Post Writer/.component.json';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';

/**
 * **How to get preset ids:**
 *
 * 1. Add `&experiments=rightClickDebug` in Blocks editor.
 * 2. Right click on the widget and select "Debug > BoB App > Get Presets"
 **/
const blocksWidgets = {
  postHeader: postHeaderJson,
  postCategories: postCategoriesJson,
  postCounters: postCountersJson,
  postMetadata: postMetadataJson,
  postRatings: postRatingsJson,
  postShareButtons: postShareButtonsJson,
  postTags: postTagsJson,
  postTitle: postTitleJson,
  postWriter: postWriterJson,
} as const;

export async function addBlocksWidget(
  context: EditorAppContext,
  widgetName: keyof typeof blocksWidgets,
) {
  const widget = blocksWidgets[widgetName];
  const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
    context.appToken,
    { tpaPageId: TPA_PAGE_ID_POST },
  );

  const componentRef = await context.sdk.application.appStudioWidgets.addWidget(
    context.appToken,
    {
      installationType: 'closed',
      containerRef: { id: pageRef.id, type: 'DESKTOP' },
      presetIds: {
        layout: widget.defaultPresetId,
        style: widget.defaultPresetId,
      },
      scopedPresets: {
        desktop: {
          layout: widget.defaultPresetId,
          style: widget.defaultPresetId,
        },
        mobile: {
          layout: widget.defaultPresetId,
          style: widget.defaultPresetId,
        },
      },
      widgetId: widget.id,
      layout: { x: 0, y: 0, width: 980, height: 600 },
      layouts: {
        componentLayout: {
          type: 'ComponentLayout',
          hidden: false,
          height: { type: 'auto' },
          minHeight: { type: 'px', value: 600 },
          width: { type: 'px', value: 980 },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          justifySelf: 'center',
          type: 'GridItemLayout',
          gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
          margins: {
            top: { type: 'px', value: 0 },
            left: { type: 'px', value: 0 },
          },
        },
        containerLayout: {
          type: 'GridContainerLayout',
          columns: [{ type: 'fr', value: 1 }],
          rows: [{ type: 'fr', value: 1 }],
        },
      },
    },
  );

  return componentRef;
}
