import type { ComponentRef } from '@wix/platform-editor-sdk';
import { WIDGET_ROLES } from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../../types/editor-app-context.type';
import { blogAppDefId, blogCommentsAppDefId } from '../../constants/apps';
import { TPA_PAGE_ID_POST } from '../../constants/tpa-pages';
import { POST_WIDGET_ID } from '../../constants/widgets';
import { addBlocksWidget } from '../add-blocks-widgets';
import concurrentEditing from '../concurrent-editing';
import { getComponentRef } from '../magic-migration/sdk-utils';
import { updateTPASettings } from '../tpa-settings';
import {
  createCommentsDefinition,
  createRelatedPostsDefinition,
} from './component-definitions';

export async function splitPostPage(context: EditorAppContext) {
  const { sdk, appToken } = context;

  console.log('[Blog] Splitting the post page...');

  await updateTPASettings(context, { postPageSplitEnabled: true });
  await concurrentEditing.withApproval(async () => {
    try {
      await addWidgets();

      console.log('[Blog] Splitting the post page... Done!');
    } catch (err) {
      console.error('[Blog] Splitting the post page... Failed!', err);
      throw err;
    }
  });

  /**
   * Adds the widgets in a following order:
   * 1. Post Header
   * 2. Post Widget (content of the post)
   * 3. Related Posts
   * 4. Comments
   */
  async function addWidgets() {
    // 1
    const postHeaderRef = await addBlocksWidget(context, 'postHeader');
    console.log('[Blog] postHeaderRef', postHeaderRef);

    // 2
    const postRef = await getPostWidget();
    await sdk.document.components.layout.update(context.appToken, {
      componentRef: postRef,
      layout: { y: await getBottomPosition(postHeaderRef) },
    });
    console.log('[Blog] postRef', postRef);

    // 3
    const relatedPostsRef = await addRelatedPostsWidget(
      await getBottomPosition(postRef),
    );
    console.log('[Blog] relatedPostsRef', relatedPostsRef);

    // 4
    const commentsRef = await addCommentsWidget(
      await getBottomPosition(relatedPostsRef),
    );
    console.log('[Blog] commentsRef', commentsRef);
  }

  async function addRelatedPostsWidget(positionY: number) {
    const pageRef = await sdk.tpa.getPageRefByTPAPageId(appToken, {
      tpaPageId: TPA_PAGE_ID_POST,
    });
    const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
    const componentDefinition = createRelatedPostsDefinition({
      applicationId: appData.applicationId,
      positionY,
    });
    const componentRef = await sdk.document.components.add(appToken, {
      componentDefinition,
      pageRef,
    });

    if (!componentRef) {
      throw new Error('Could not add the related posts widget');
    }

    const connection = await connectWidgetToPost({
      componentRef,
      role: WIDGET_ROLES.RELATED_POSTS,
    });

    if (!connection) {
      throw new Error('Could not connect the related posts widget');
    }

    return componentRef;
  }

  async function addCommentsWidget(positionY: number) {
    const appData = await sdk.tpa.add
      .application(appToken, {
        appDefinitionId: blogCommentsAppDefId,
      })
      .then(() =>
        sdk.tpa.app.getDataByAppDefId(appToken, blogCommentsAppDefId),
      );

    const componentRef = await sdk.document.components.add(appToken, {
      componentDefinition: createCommentsDefinition({
        applicationId: appData.applicationId,
        positionY,
      }),
      pageRef: await sdk.tpa.getPageRefByTPAPageId(appToken, {
        tpaPageId: TPA_PAGE_ID_POST,
      }),
    });

    if (!componentRef) {
      throw new Error('Could not add the comments widget');
    }

    const commentsConnection = await connectWidgetToPost({
      componentRef,
      role: WIDGET_ROLES.COMMENTS,
    });

    if (!commentsConnection) {
      throw new Error('Could not connect the comments widget');
    }

    return componentRef;
  }

  async function connectWidgetToPost({
    componentRef,
    role,
  }: {
    componentRef: ComponentRef;
    role: string;
  }) {
    const postComponentRef = await getComponentRef(sdk, POST_WIDGET_ID);
    if (!postComponentRef) {
      return;
    }

    const connectionData = await sdk.document.controllers.connect(appToken, {
      connectToRef: componentRef,
      controllerRef: postComponentRef,
      role,
      isPrimary: false,
    });

    return connectionData;
  }

  async function getPostWidget() {
    const componentRef = await getComponentRef(context.sdk, POST_WIDGET_ID);

    if (!componentRef) {
      throw new Error('Could not retrieve the post widget');
    }

    return componentRef;
  }

  async function getBottomPosition(componentRef: ComponentRef) {
    const layout = await sdk.document.components.layout.get(appToken, {
      componentRef,
    });

    return layout.y + layout.height;
  }
}
